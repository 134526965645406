import React, { useState } from 'react';
import './styles/CebirStudentCreater.css'; // CSS dosyanızı buraya ekleyin

const CebirStudentCreate = () => {
    const [students, setStudents] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [form, setForm] = useState({
        fullName: '',
        familyFullName: '',
        family2FullName: '',
        familyPhone: '',
        studentPhone: '',
        studentClass: '',
        studentCities: '',
        studentDistrict: '',
        studentShift: '',
        email: '',
        studentBirthDate: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // POST isteği ile form verilerini backend'e gönder
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form) // Form verilerini JSON formatında gönder
            });

            if (response.ok) {
                const newStudent = await response.json(); // Yanıtı JSON olarak al
                setStudents([...students, newStudent]); // Yeni öğrenciyi mevcut listeye ekle
                setForm({
                    fullName: '',
                    familyFullName: '',
                    family2FullName: '',
                    familyPhone: '',
                    studentPhone: '',
                    studentClass: '',
                    studentCities: '',
                    studentDistrict: '',
                    studentShift: '',
                    email: '',
                    studentBirthDate: '',
                    password: ''
                });
                setNotificationMessage('Öğrenci başarıyla eklendi!');
                setNotificationType('success');
            } else {
                setNotificationMessage('Öğrenci eklenirken bir hata oluştu');
                setNotificationType('error');
            }
        } catch (error) {
            setNotificationMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
            setNotificationType('error');
        } finally {
            setTimeout(() => {
                setNotificationMessage('');
                setNotificationType('');
            }, 3000); // 3 saniye sonra notifikasyonu temizle
        }
    };

    return (
        <div className="create-register-container">
            <div className="create-register-form-container">
                <h1>Cebir Öğrenci Kayıt</h1>
                {notificationMessage && (
                    <div className={`create-notification ${notificationType}`}>
                        {notificationMessage}
                    </div>
                )}
                <form className="create-register-form" onSubmit={handleSubmit}>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={form.fullName}
                            onChange={handleChange}
                            placeholder="Öğrenci Adı Soyadı"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="familyFullName"
                            name="familyFullName"
                            value={form.familyFullName}
                            onChange={handleChange}
                            placeholder="Veli Adı Soyadı"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="family2FullName"
                            name="family2FullName"
                            value={form.family2FullName}
                            onChange={handleChange}
                            placeholder="İkinci Veli Adı Soyadı (İsteğe Bağlı)"
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="familyPhone"
                            name="familyPhone"
                            value={form.familyPhone}
                            onChange={handleChange}
                            placeholder="Veli Telefon Numarası"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="studentPhone"
                            name="studentPhone"
                            value={form.studentPhone}
                            onChange={handleChange}
                            placeholder="Öğrenci Telefon Numarası"
                        />
                    </div>
                    <div className="create-form-group">
                        <select
                            id="studentClass"
                            name="studentClass"
                            value={form.studentClass}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Öğrenci sınıfı seçiniz</option>
                            <option value="1.sınıf">1.sınıf</option>
                            <option value="2.sınıf">2.sınıf</option>
                            <option value="3.sınıf">3.sınıf</option>
                            <option value="4.sınıf">4.sınıf</option>
                            <option value="5.sınıf">5.sınıf</option>
                            <option value="6.sınıf">6.sınıf</option>
                            <option value="7.sınıf">7.sınıf</option>
                            <option value="8.sınıf">8.sınıf</option>
                            <option value="9.sınıf">9.sınıf</option>
                            <option value="10.sınıf">10.sınıf</option>
                            <option value="11.sınıf">11.sınıf</option>
                            <option value="12.sınıf">12.sınıf</option>
                            <option value="Mezun öğrenci">Mezun</option>
                            <option value="Üniversite öğrencisi">Üniversite öğrencisi</option>
                            <option value="Yetişkin">Yetişkin</option>
                        </select>
                    </div>
                    <section className="create-location-section">
                        
                            <input
                                type='text'
                                id="city-select"
                                name="studentCities"
                                value={form.studentCities}
                                onChange={handleChange}
                                placeholder='Şehir Yazınız'
                                required
                            >
                                
                            </input>
                        
                       
                            <input
                                type='text'
                                id="district-select"
                                name="studentDistrict"
                                value={form.studentDistrict}
                                onChange={handleChange}
                                placeholder='İlçe Yazınız'
                                required
                            >
                                
                            </input>
                      
                    </section>
                    <div className="create-form-group">
                        <select
                            id="studentShift"
                            name="studentShift"
                            value={form.studentShift}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Öğrenci okul saatleri</option>
                            <option value="Sabah">Sabahcı</option>
                            <option value="Öğlen">Öğlenci</option>
                            <option value="Akşam">Tam Gün</option>
                        </select>
                    </div>
                    <div className="create-form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            placeholder="E-posta"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="date"
                            id="studentBirthDate"
                            name="studentBirthDate"
                            value={form.studentBirthDate}
                            onChange={handleChange}
                            placeholder="Öğrenci Doğum Tarihi"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                            placeholder="Şifre"
                            required
                        />
                    </div>
                    <button type="submit" className="create-submit-button">Öğrenci Ekle</button>
                </form>
            </div>
        </div>
    );
};

export default CebirStudentCreate;
