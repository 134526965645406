import React, { useState, useEffect } from 'react';
import './styles/CebirStudentDeleter.css'; // CSS dosyasını import edin

const CebirStudentDeleter = () => {
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');

    // Öğrencileri backend'den çek
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/all`);
                const data = await response.json();
                setStudents(data);
            } catch (error) {
                console.error('Öğrenciler alınırken bir hata oluştu:', error);
            }
        };

        fetchStudents();
    }, []);

    const handleStudentSelect = (student) => {
        setSelectedStudent(student);
    };

    const handleDelete = async () => {
        if (selectedStudent) {
            try {
                const id = Number(selectedStudent.id);
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/${id}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    setStudents(students.filter(student => student.id !== selectedStudent.id));
                    setNotificationMessage('Öğrenci başarıyla silindi.');
                    setNotificationType('success');
                } else {
                    setNotificationMessage('Öğrenci silinirken bir hata oluştu.');
                    setNotificationType('error');
                }
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000); // Notifikasyon 3 saniye sonra kaybolur
            } catch (error) {
                console.error('Hata:', error);
                setNotificationMessage('Öğrenci silinirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000); // Notifikasyon 3 saniye sonra kaybolur
            }
            setSelectedStudent(null); // Seçili öğrenciyi sıfırla
        }
    };

    return (
        <div className="student-deleter">
            <h2>Cebir Öğrenci Sil</h2>
            {/* Notifikasyon Alanı */}
            {notificationMessage && (
                <div className={`notification ${notificationType}`}>
                    {notificationMessage}
                </div>
            )}
            <div className="deleter-student-selector">
                <h3>Öğrenci Seç:</h3>
                {students.length > 0 ? (
                    <ul className="deleter-student-list">
                        {students.map((student) => (
                            <li key={student.id}>
                                <span>{student.fullName}</span>
                                <button onClick={() => handleStudentSelect(student)}>Sil</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Silinecek öğrenci bulunamadı.</p>
                )}
            </div>

            {/* Silme Onaylama Kısmı */}
            {selectedStudent && (
                <div className="confirmation-dialog">
                    <p>{selectedStudent.fullName} isimli öğrenciyi silmek istediğinizden emin misiniz?</p>
                    <button onClick={handleDelete}>Evet, Sil</button>
                    <button onClick={() => setSelectedStudent(null)}>İptal</button>
                </div>
            )}
        </div>
    );
};

export default CebirStudentDeleter;
