import React, { useState, useEffect } from 'react';
import './styles/CebirStudentList.css'; // CSS dosyasını import edin

const CebirStudentList = ({ onStudentSelect }) => {
    const [students, setStudents] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');

    // Öğrencileri backend'den çek
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/all`);
                const data = await response.json();
                setStudents(data);
            } catch (error) {
                console.error('Öğrenciler alınırken bir hata oluştu:', error);
                setNotificationMessage('Öğrenciler yüklenirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000); // Notifikasyon 3 saniye sonra kaybolur
            }
        };

        fetchStudents();
    }, []);

    return (
        <div className="student-list-container">
            <h2>Cebir Öğrenci Listesi</h2>
            {/* Notifikasyon Alanı */}
            {notificationMessage && (
                <div className={`notification ${notificationType}`}>
                    {notificationMessage}
                </div>
            )}
            <div className="student-list-wrapper">
                {students.length > 0 ? (
                    <ul className="student-list">
                        {students.map((student) => (
                            <li key={student.id} className="student-list-item">
                                <span className="student-name">{student.fullName}</span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Öğrenci bulunamadı.</p>
                )}
            </div>
        </div>
    );
};

export default CebirStudentList;
