import React, { useState, useEffect } from 'react';
import "./styles/CebirStudentEditor.css" // CSS dosyanızı buraya ekleyin

const CebirStudentEditor = () => {
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState(''); 
    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState({
        fullName: '',
        familyFullName: '',
        family2FullName: '',
        familyPhone: '',
        studentPhone: '',
        studentClass: '',
        studentCities: '',
        studentDistrict: '',
        studentShift: '',
        email: '',
        studentBirthDate: '',
        password: ''
    });

    // Mevcut öğrencileri backend'den çek
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/all`);
                const data = await response.json();
                setStudents(data);
            } catch (error) {
                console.error('Öğrenciler alınırken bir hata oluştu:', error);
            }
        };

        fetchStudents();
    }, []);

    const handleStudentSelect = (student) => {
        setSelectedStudent(student);
    };



    const handleUpdate = async () => {
        if (selectedStudent) {
            try {

                const id = Number(selectedStudent.id);
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(selectedStudent)
                });

                if (response.ok) {
                    setStudents(students.map(student => student.id === selectedStudent.id ? selectedStudent : student));
                    setNotificationMessage('Öğrenci başarıyla güncellendi.');
                    setNotificationType('success');
                } else {
                    setNotificationMessage('Öğrenci güncellenirken bir hata oluştu.');
                    setNotificationType('error');
                }
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000); // Notifikasyon 3 saniye sonra kaybolur
            } catch (error) {
                console.error('Hata:', error);
                setNotificationMessage('Öğrenci güncellenirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000); // Notifikasyon 3 saniye sonra kaybolur
            }
            setShowModal(false); // Modalı kapat
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedStudent(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="student-editor">
            <h2>Cebir Öğrenci Düzenle</h2>
            <div className="editor-student-selector">
                <h3>Öğrenci Seç:</h3>
                {/* Notifikasyon Alanı */}
                {notificationMessage && (
                    <div className={`notification ${notificationType}`}>
                        {notificationMessage}
                    </div>
                )}
                <ul className="student-list">
                    {students.map((student) => (
                        <li key={student.id}>
                            <span>{student.fullName}</span>
                            <button onClick={() => handleStudentSelect(student)}>Düzenle</button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Düzenleme Formu */}
            {selectedStudent && (
                
                <div className="register-container">
                    
                    <div className="editor-register-form-container">
                        <h1>Öğrenci Düzenle</h1>
                       
                        <form className="register-form">
                        {notificationMessage && (
                            <div className={`notification ${notificationType}`}>
                                {notificationMessage}
                            </div>
                        )}
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    value={selectedStudent.fullName}
                                    onChange={handleChange}
                                    placeholder="Öğrenci Adı Soyadı"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="familyFullName"
                                    name="familyFullName"
                                    value={selectedStudent.familyFullName}
                                    onChange={handleChange}
                                    placeholder="Veli Adı Soyadı"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="family2FullName"
                                    name="family2FullName"
                                    value={selectedStudent.family2FullName}
                                    onChange={handleChange}
                                    placeholder="İkinci Veli Adı Soyadı (İsteğe Bağlı)"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="familyPhone"
                                    name="familyPhone"
                                    value={selectedStudent.familyPhone}
                                    onChange={handleChange}
                                    placeholder="Veli Telefon Numarası"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="studentPhone"
                                    name="studentPhone"
                                    value={selectedStudent.studentPhone}
                                    onChange={handleChange}
                                    placeholder="Öğrenci Telefon Numarası"
                                />
                            </div>
                            <div className="form-group">
                                <select
                                    id="studentClass"
                                    name="studentClass"
                                    value={selectedStudent.studentClass}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Öğrenci sınıfı seçiniz</option>
                                    <option value="1.sınıf">1.sınıf</option>
                                    <option value="2.sınıf">2.sınıf</option>
                                    <option value="3.sınıf">3.sınıf</option>
                                    <option value="4.sınıf">4.sınıf</option>
                                    <option value="5.sınıf">5.sınıf</option>
                                    <option value="6.sınıf">6.sınıf</option>
                                    <option value="7.sınıf">7.sınıf</option>
                                    <option value="8.sınıf">8.sınıf</option>
                                    <option value="9.sınıf">9.sınıf</option>
                                    <option value="10.sınıf">10.sınıf</option>
                                    <option value="11.sınıf">11.sınıf</option>
                                    <option value="12.sınıf">12.sınıf</option>
                                    <option value="Mezun öğrenci">Mezun</option>
                                    <option value="Üniversite öğrencisi">Üniversite öğrencisi</option>
                                    <option value="Yetişkin">Yetişkin</option>
                                </select>
                            </div>
                            <section className="location-section">
                                <div className="form-group">
                                
                                    <input
                                        id="city-select"
                                        name="studentCities"
                                        value={selectedStudent.studentCities}
                                        onChange={handleChange}
                                        required
                                    >                
                                    </input>
                                </div>
                                <div className="form-group">
                                    <input
                                        id="district-select"
                                        name="studentDistrict"
                                        value={selectedStudent.studentDistrict}
                                        onChange={handleChange}
                                        required
                                    >
                                
                                    </input>
                                </div>
                            </section>
                            <div className="form-group">
                                <select
                                    id="studentShift"
                                    name="studentShift"
                                    value={selectedStudent.studentShift}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Öğrenci okul saatleri</option>
                                    <option value="Sabah">Sabahcı</option>
                                    <option value="Öğlen">Öğlenci</option>
                                    <option value="Akşam">Tam Gün</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={selectedStudent.email}
                                    onChange={handleChange}
                                    placeholder="E-posta"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="date"
                                    id="studentBirthDate"
                                    name="studentBirthDate"
                                    value={selectedStudent.studentBirthDate}
                                    onChange={handleChange}
                                    placeholder="Öğrenci Doğum Tarihi"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={selectedStudent.password}
                                    onChange={handleChange}
                                    placeholder="Şifre"
                                    required
                                />
                            </div>
                            <button type="submit" className="submit-button" onClick={handleUpdate}>Güncelle</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CebirStudentEditor;


