import React, { useState } from 'react';
import StudentCreate from './StudentCreate'; // Kurs ekleme bileşeni
import "./StudentManager.css"
import StudentEditor from './StudentEditor';
import StudentDeleter from './StudentDeleter';
import StudentList from './StudentList';
import CebirStudentList from '../AdminDashBoard/CebirStudents/CebirStudentList';
import CebirStudentCreate from '../AdminDashBoard/CebirStudents/CebirStudentCreater';
import CebirCourseEditor from '../AdminDashBoard/CebirStudents/CebirStudentEditor';
import CebirStudentDeleter from '../AdminDashBoard/CebirStudents/CebirStudentDeleter';

const StudentManager = () => {
    const [activePage, setActivePage] = useState('default'); 
    const renderContent = () => {
        switch (activePage) {
            case 'ekle':
             return <StudentCreate />; // Öğrenci ekleme bileşenini render et
            case 'duzenle':
             return <StudentEditor />; // Öğrenci düzenleme bileşenini render et
            case 'sil':
             return  <StudentDeleter /> // Öğrenci silme bileşenini render et 

               case 'cebirekle':
                return <CebirStudentCreate/>// Öğrenci ekleme bileşenini render et
               case 'cebirduzenle':
                return <CebirCourseEditor/>; // Öğrenci düzenleme bileşenini render et
               case 'cebirsil':
                return  <CebirStudentDeleter /> // Öğrenci silme bileşenini render et 
               case 'cebirlistele':
                return <CebirStudentList/>


            default:
            return <StudentList />; // Varsayılan olarak Öğrenci Listesi
        }
    };

    return (
        <div className="student-manager-panel">
            <div className="sidebar">
                <button onClick={() => setActivePage('ekle')}>Anka Öğrenci Ekle</button>
                <button onClick={() => setActivePage('duzenle')}>Anka Öğrenci Kaydı Düzenle</button>
                <button onClick={() => setActivePage('sil')}>Anka Öğrenci Kaydı Sil</button>
                <button onClick={() => setActivePage('listele')}>Anka Öğrenci Kayıtları</button>
                <button onClick={() => setActivePage('cebirekle')}>Cebir Öğrenci Ekle</button>
                <button onClick={() => setActivePage('cebirduzenle')}>Cebir Öğrenci Kaydı Düzenle</button>
                <button onClick={() => setActivePage('cebirsil')}>Cebir Öğrenci Kaydı Sil</button>
                <button onClick={() => setActivePage('cebirlistele')}>Cebir Öğrenci Kayıtları</button>
            </div>
            <div className="content">
                {renderContent()} {/* Seçili sayfaya göre içerik render ediliyor */}
            </div>
        </div>
    );
};

export default StudentManager;
