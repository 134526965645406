import React, { useState } from 'react';
import CourseCreator from '../CourseCard/CourseCreator'; // Kurs ekleme bileşeni
import CourseEditor from '../CourseCard/CourseEditor';
import CourseDeleter from '../CourseCard/CourseDeleter';
import CebirCourseCreator from './CebirCourse/CebirCourseCreator';
import "./CoursePanel.css"
import CebirCourseEditor from './CebirCourse/CebirCourseEditor';
import CebirCourseDeleter from './CebirCourse/CebirCourseDeleter';

const CoursePanel = () => {
    const [activePage, setActivePage] = useState('ekle'); // Başlangıç olarak 'Kurs Ekleme' seçili

    const renderContent = () => {
        switch (activePage) {
            case 'ekle':
                return <CourseCreator />; // Kurs ekleme bileşenini render et
            case 'duzenle':
                return <CourseEditor />; // Kurs düzenleme bileşenini render et
            case 'sil':
              return  <CourseDeleter /> // Kurs silme bileşenini render et 

            case 'cebirEkle':
                return <CebirCourseCreator/>;

            case 'cebirDuzenle':
                return <CebirCourseEditor/>;
            
            case 'cebirSil':
                return <CebirCourseDeleter/>;


            default:
                return <CebirCourseCreator/>; // Varsayılan olarak Kurs Ekleme
        }
    };

    return (
        <div className="admin-panel">
            <div className="sidebar">
                <button onClick={() => setActivePage('ekle')}>Anka Kurs Ekle</button>
                <button onClick={() => setActivePage('duzenle')}>Anka Kurs Düzenle</button>
                <button onClick={() => setActivePage('sil')}>Anka Kurs Sil</button>
                <button onClick={() => setActivePage('cebirEkle')}>Cebir Kurs Ekle</button>
                <button onClick={() => setActivePage('cebirDuzenle')}>Cebir Kurs Düzenle</button>
                <button onClick={() => setActivePage('cebirSil')}>Cebir Kurs Sil</button>
                
            </div>
            <div className="content">
                {renderContent()} {/* Seçili sayfaya göre içerik render ediliyor */}
            </div>
        </div>
    );
};

export default CoursePanel;
