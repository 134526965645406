import React, { useState, useEffect } from 'react';
import './styles/CebirCourseDeleter.css'; // CSS dosyanızı buraya ekleyin

const CebirCourseDeleter = () => {
    const [courses, setCourses] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState(''); 

    // Mevcut kursları backend'den çek
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/admin/course/cebir/all`);
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Kurslar alınırken bir hata oluştu:', error);
            }
        };

        fetchCourses();
    }, []);

    // Kursu sil
    const handleDelete = async () => {
        if (selectedCourseId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/admin/course/cebir/delete/${selectedCourseId}`, {
                    method: 'DELETE'
                });

                if (response.ok) {
                    setCourses(courses.filter(course => course.id !== selectedCourseId));
                    setNotificationMessage('Kurs başarıyla silindi.');
                    setNotificationType('success');
                } else {
                    setNotificationMessage('Kurs silinirken bir hata oluştu.');
                    setNotificationType('error');
                }
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000); // Notifikasyon 3 saniye sonra kaybolur
            } catch (error) {
                console.error('Hata:', error);
                setNotificationMessage('Kurs silinirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000); // Notifikasyon 3 saniye sonra kaybolur
            }
            setShowModal(false); // Modalı kapat
        }
    };

    return (
        <div className="course-deleter">
            <h2>Cebir Kurs Sil</h2>
            <div className="deleter-course-selector">
                <h3>Kurs Seç:</h3>
                {/* Notifikasyon Alanı */}
                {notificationMessage && (
                    <div className={`notification ${notificationType}`}>
                        {notificationMessage}
                    </div>
                )}
                <ul className="course-list">
                    {courses.map((course) => (
                        <li key={course.id}>
                            <span>{course.title}</span>
                            <button onClick={() => {
                                setSelectedCourseId(course.id);
                                setShowModal(true);
                            }}>Sil</button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Onay Modalı */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Silmek İstediğinize Emin Misiniz?</h3>
                        <button onClick={() => handleDelete()}>Evet</button>
                        <button onClick={() => setShowModal(false)}>Hayır</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CebirCourseDeleter;
